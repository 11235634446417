/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pickadate@3.6.4/lib/compressed/picker.min.js
 * - /npm/pickadate@3.6.4/lib/compressed/picker.date.min.js
 * - /npm/pickadate@3.6.4/lib/compressed/picker.time.min.js
 * - /npm/swiper@4.5.0/dist/js/swiper.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
